<template>
    <div id="locations">
        <Accordion id="locationMainAccordion">
            <template v-slot:accordionItem>
                <div class="accordion-item">
                    <div id="collapseOneLocation" class="accordion-collapse collapse" aria-labelledby="headingL" data-bs-parent="#locationMainAccordion">
                        <strong>Busca por estado ou município</strong>
                        <form>
                            <div class="my-3">
                                 <!-- type="state" class="form-select" id="state" -->
                                <states @selected="fitStateZoom"></states>
                            </div>
                            <div class="mb-3">
                                 <!-- type="city" class="form-control" id="city" placeholder="Município" -->
                                <cities @selected="fitCityZoom" :siglaUf="sigla_uf"></cities>
                            </div>
                        </form>
                    </div>
                    
                    <div class="accordion-header" id="headingL">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneLocation" aria-expanded="true" aria-controls="collapseOneLocation">
                            <div class="location-circles">
                                <figure class="gg-flag"></figure>
                            </div>                                                   
                        </button>
                    </div>
                </div>
            </template>
        </Accordion>
    </div>
</template>

<script>
import Accordion from  '@/components/atoms/Accordion/Accordion'
import States from  '../../molecules/Thypeaheads/States'
import Cities from  '../../molecules/Thypeaheads/Cities'
import { mapGetters } from 'vuex'
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import {Stroke, Style} from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import {easeOut} from 'ol/easing';

export default {
    name:"Locations",
    components:{Accordion,States,Cities},
    props:['olMap'],
    data() {
        return {
            view: this.$props.olMap.getView(),
            map: this.$props.olMap,
            zoom: 0,
            sigla_uf: '',
            cd_mun: '',
            vectorLayer:''
        }
    },
    computed: {
        ...mapGetters('geoserver', {
            loadingStatus: 'loadingStatus',
            getBbox: 'getBbox',
            getGeoJsonObject: 'getGeoJsonObject'
        })
    },
    watch: {
        loadingStatus: function() {
            if (this.loadingStatus === false) {
                this.doFlyTo()
            }
        }
    },
    methods: {
        fitCityZoom(cd_mun) {
            this.$store.dispatch('geoserver/getGeoDataByCdMun',cd_mun)
            this.zoom = 10
        },
        fitStateZoom(ufArr) {
            let cd_uf = ufArr[0]
            let sigla_uf = ufArr[1]
            this.sigla_uf = sigla_uf
            if (cd_uf != '') {
                this.$store.dispatch('geoserver/getGeoDataByCdUf',cd_uf)
                this.zoom = 7
            }
        },
        doFlyTo() {
            if (!this.loadingStatus) {
                if (this.vectorLayer != '') {
                    this.map.removeLayer(this.vectorLayer)
                }
                const geoJsonObj = this.getGeoJsonObject;
                const source = new VectorSource({
                    features: (new GeoJSON()).readFeatures(geoJsonObj),
                    format: new GeoJSON(),
                });

                // var lightStroke = new ol.style.Style({
                // stroke: new ol.style.Stroke({
                //     color: [255, 255, 255, 0.6],
                //     width: 2,
                //     lineDash: [4,8],
                //     lineDashOffset: 6
                // })
                // });

                // var darkStroke = new ol.style.Style({
                // stroke: new ol.style.Stroke({
                //     color: [0, 0, 0, 0.6],
                //     width: 2,
                //     lineDash: [4,8]
                // })
                // });
                const lightStroke = new Style({
                    stroke: new Stroke({
                        color: '#ffffff',
                        width: 8
                    })
                });
                const darkStroke = new Style({
                    stroke: new Stroke({
                        color: '#000000',
                        width: 3
                    })
                });
                this.vectorLayer = new VectorLayer({
                    source: source,
                    style: [lightStroke,darkStroke],
                    zIndex: 10000,
                }); 
                this.map.addLayer(this.vectorLayer)               
                const feature = source.getFeatures()[0];
                const polygon = feature.getGeometry();
                polygon.transform('EPSG:4326', 'EPSG:3857')
                this.view.fit(polygon, {padding: [170, 50, 30, 150],easing:easeOut,duration: 1000});
            } 
        }
    }
}
</script>

<style lang="scss" scoped>
#locations{
    top:unset!important;
    left: 93.5% !important;
    bottom: 205px !important;
    padding-left: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    z-index: 10;
    position: absolute;
    .accordion-item:last-of-type {
        background-color: transparent!important;
    }
    .accordion-item:last-of-type .accordion-collapse {
        background-color: white;
        width: 300px;
        height: 210px;
        border-bottom-right-radius: 0rem;
        border-bottom-left-radius: 0rem;
        margin-left: -14rem;
        margin-bottom: -3.2rem;
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300853F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
        transform: rotate(0deg);
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important;
    } 
    .accordion-button.collapsed::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300853F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
        transform: rotate(-180deg);
    } 
    .accordion-button:focus {
        box-shadow: 0 0 0 0 #fff;
    }
}
.gg-flag {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 18px;
    border-left: 2px #108442 solid;
    margin: 0;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}
.gg-flag::after,
.gg-flag::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 8px;
    height: 8px
}
.gg-flag::before {
    border-top: 2px #108442 solid;
    border-bottom: 2px #108442 solid
}
.gg-flag::after {
    border: 2px #108442 solid;
    border-left: 0;
    top: 2px;
    left: 7px
}
.form-select {
    border: 1px solid #108442
}
.form-control {
    border: 1px solid #108442
}
#locations #collapseOneLocation {
    padding: 1.25rem;
}

#locations .accordion-button::after {
    margin-left: 1rem !important;
}

#locations .collapsed{
    width: 150px!important;
}
#locations .accordion-button {
    width: 75px!important;
}

.location-circles {
    position: absolute;
    right: 0.125rem;
    bottom: 0.5rem;
}

.location-circles .circle {
    display: inline-block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
  @media only screen and (max-width: 600px) {
    #locations{
        left: 77%!important;
        bottom: 130px !important;
        z-index: 100;
    }
}
  @media only screen and (min-width: 1600px) {
    #locations{
        left: 95.5% !important;
    }
}
</style>
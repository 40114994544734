<template>
  <loader v-if="loadingStatus"></loader>
  <div class="row px-0 mx-0 map-container" v-if="!loadingStatus">
      <Filters></Filters>
      <Legend></Legend>
      <Location :olMap="olMap" v-if="olMap"></Location>
      <slider :cd_mun="cd_mun" :cd_uf="cd_uf" :mun_uf_name="mun_uf_name"></slider>
      <open-layer></open-layer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filters from "../../organisms/Map/Filters"
import Legend from "../../organisms/Map/Legend"
import Location from "../../organisms/Map/Location"
import Slider from "../../organisms/Slides/Slider"
import OpenLayer from "../../organisms/Map/OpenLayer"
export default {
    name:"Map",
    data() {
      return {
        olMap:false,
        cd_mun: 0,
        cd_uf: 0,
        mun_uf_name: ''
      }
    },
    components:{OpenLayer,Slider,Filters,Legend,Location},
    computed: {
      ...mapGetters('mapLayers', {
          loadingStatus: 'loadingStatus'
      })
    },
    created() {
      this.$store.dispatch('mapLayers/getAllLayers')
    }
}
</script>

<style lang="scss">
.map-container {
  height: calc(100vh - 150px);
}
#filters {
  position: absolute; 
  left: -8px;
  top: 10%;
  z-index:10;
}
#legends {
  position: absolute; 
  left: -8px;
  top: 22%;
  z-index:11;
}
</style>

<template>
  <Map />
</template>

<script>
import Map from "../templates/Map/Map"
export default {
    name:"PageMap",
    components:{Map}
}
</script>

<style>

</style>
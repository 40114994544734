<template>
      <li v-if="node.type == 'Accordion'" :class="'sublist-padding '  + (Object.keys(node.subitems).length > 0) ? ' border-green': ''">
        <item :label="node.label" :name="node.name" :accordionId="node.name">
          <template v-slot:itemBody>
            <h2 class="accordion-header" :id="'filter-heading-'+node.name">
              <button class="accordion-button collapsed filtro" type="button" data-bs-toggle="collapse" :data-bs-target="'#filter-collapse-'+node.name" aria-expanded="false" :aria-controls="'filter-collapse-'+node.name">
                {{node.label}} <counter v-if="node.noCounter == 0 || node.noCounter == undefined" :node="node" /> 
              </button>
            </h2>
          </template>
          <template v-slot:subitemBody>
            <div :id="'filter-collapse-'+node.name" class="accordion-collapse collapse" :aria-labelledby="'filter-heading-'+node.name" :data-bs-parent="'#'+node.name">
              <div class="accordion-body">
                <ul v-if="Object.keys(node.subitems).length > 0">
                  <li class="sublist-padding border-green">
                    <FilterItems v-for="(subitem,index) in node.subitems" :node="subitem" :key="index"></FilterItems>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </item>
      </li>
      <Switch :label="node.label" @change="checked($event)" :name="node.name" :checked="(node.checked) ? node.checked : 'false'" :id="node.id" :ref="node.name" :value="node.value" v-if="node.type == 'Switch'"></Switch>
      <SwitchTwoLabels :label="node.label" :label2="node.label2" @change="checked($event)" :name="node.name" :checked="(node.checked) ? node.checked : 'false'" :id="node.id" :ref="node.name" :value="node.value" :value2="node.value2" v-if="node.type == 'SwitchTwoLabels'"></SwitchTwoLabels>
      <checkbox :label="node.label" @change="checked($event)" :name="node.name" :checked="(node.checked) ? node.checked : 'false'" :id="node.id" :ref="node.name" :value="node.value" v-if="node.type == 'Checkbox'"></checkbox>
      <radio :label="node.label" @change="checked($event)" :name="node.name" :checked="(node.checked) ? node.checked : 'false'" :id="node.id" :ref="node.name" :value="node.value" v-if="node.type == 'Radiobutton'"></radio>
</template>

<script>
import Switch from "@/components/atoms/Form/Switch.vue"
import Radio from "@/components/atoms/Form/Radiobutton.vue"
import SwitchTwoLabels from "@/components/atoms/Form/SwitchTwoLabels.vue"
import Checkbox from "@/components/atoms/Form/Checkbox.vue"
import Item from "@/components/atoms/Accordion/Item"
import Counter from "./FilterCounter"

export default {
    name: 'FilterItems',
    props: {
        'node': {
            type:Object,
            required:true
        }
    },
    data: function() {
      return {
        IsChecked: function() {
          let elm = document.querySelector("#" + this.node.id);
          return elm.checked;
        },
        DefaultValue: Object
      }
    },
    components: { 
        Checkbox,
        Counter,
        Item,
        Radio,
        Switch,
        SwitchTwoLabels
    },
    created() {
      if(this.node.type !== "Accordion") {
        this.DefaultValue = ( this.node.checked !== undefined ? ( this.node.checked == "true" ) : false);
        this.emitter.on("filter-reset", this.reset);
      }
    },
    methods: {
      reset: function() {
        let elm = document.querySelector("#" + this.node.id);
        elm.checked = this.DefaultValue;
        this.checked(); // Chama o evento para atualizar o mapa
      },
      checked: function() {
        let e =  {
          node: this.node,
          id: this.node.id,
          name: this.node.name,
          checked: this.$refs[this.node.name].$refs[this.node.name].checked,
          noCounter: (this.node.noCounter != undefined ? this.node.noCounter : 0)
        };

        if(!e.checked && this.node.value2 != undefined) {
          e.value = this.node.value2;
        }
        else {
          e.value = this.node.value;
        }


        this.emitter.emit("filter-changed", e);
      }
    }    
}
</script>

<style lang="scss">
.sublist-padding>div, .sublist-padding > li >div{
  padding-left:3.25rem !important;
}

.border-green {
  border-bottom:2px solid #00853F;
}

.sublist-padding:last-of-type {
    border-bottom: none;
}
@media only screen and (max-width: 600px) {
  .sublist-padding>div, .sublist-padding > li >div{
    padding-left:2rem !important;
  }
} 
</style>
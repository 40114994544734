<template>
    <div id="legends">
        <loader v-if="loading"></loader>
        <Accordion id="legendMainAccordion">
            <template v-slot:accordionItem>
                <div class="accordion-item">
                    <div id="collapseOneLegend" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#legendMainAccordion">
                        <div class="accordion-body">
                            <List :list="legendsJson" v-if="!loading">
                                <template v-slot:body="slotProps">
                                    <Item :id="slotProps.node.id" :name="slotProps.node.name" :endpoint="slotProps.node.endpoint" :style="slotProps.node.style" :layer="slotProps.node.layer" :display="slotProps.node.display" :ref="slotProps.node.id" :group="slotProps.node.groupStyle"></Item>
                                </template>
                            </List>
                        </div>
                    </div>
                    
                    <div class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneLegend" aria-expanded="true" aria-controls="collapseOneLegend">
                            <TitleLegendBox id="title-legend-box" /> 
                            <div class="legend-circles">
                                <figure class="circle" style="background: #FCBA41;"></figure>
                                <figure class="circle" style="background: #1383C4;"></figure>
                                <figure class="circle" style="background: #108442;"></figure>
                                <figure class="circle" style="background: #4EC1A6;"></figure>
                            </div>                                                   
                        </button>
                    </div>
                </div>
            </template>
        </Accordion>
    </div>
</template>

<script>
import TitleLegendBox from '@/components/molecules/Titles/LegendBox'
import List from "@/components/molecules/Accordion/List"
import Item from "@/components/molecules/Legend/Item"
import Accordion from  '@/components/atoms/Accordion/Accordion'
export default {
    name:"Legends",
    components:{List,TitleLegendBox,Accordion,Item},
    data(){
        return ({
             accordionId: "accordionLegend",
             legendsJson: {},
             loading: true
        })
    },
    created(){
        this.getImport.then((data) => {
            this.legendsJson = {...data.default["legends"]}
            this.loading = false
        })
        this.emitter.on("filter-changed", this.toggleLegend)
    },
    computed: {
        getImport() {
            return import(`@/map-legends.json`)
        }
    },
    methods: {
        toggleLegend(event) {
            let groupStyle = "n_props_pct"  
            if (document.getElementById("tipo-de-variavel-switch").checked) {
                groupStyle = "area"
            }

            var displayMode = "none";
            if(event.checked !== undefined) {
                displayMode = event.checked ? "block" : "none";
            }

            if (event.id != "tipo-de-variavel-switch") {
                let id = "legend-heading-"+event.id
                if (document.getElementById(id)) {
                    document.getElementById(id).style.display = displayMode
                } else {
                    let groupId = id + "_" + groupStyle
                    if (document.getElementById(groupId)) {
                        document.getElementById(groupId).style.display = displayMode
                    }
                }
            } else {
                var legendItems = document.getElementsByClassName("legend-item");
                for (var i = 0; i < legendItems.length; i++) {
                    if (legendItems.item(i).getAttribute("group") != "none") {
                        if (legendItems.item(i).getAttribute("group") == groupStyle) {
                            let checkboxId = legendItems.item(i).id.replace('legend-heading-','')
                            checkboxId = checkboxId.replace(/_area|_n_props_pct/,'')
                            
                            if (document.getElementById(checkboxId).checked) {
                                legendItems.item(i).style.display = "block";
                            }
                        } else {
                            legendItems.item(i).style.display = "none";
                        }
                    }
                }
            }
        }
    }

}
</script>

<style lang="scss" scoped>
#legends{
    width: 479px;
    top:unset!important;
    left:0!important;
    bottom: 120px!important;
    bottom: 120px!important;
    padding-left: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    .accordion-item:last-of-type {
        background-color: transparent!important;
    }
    .accordion-item:last-of-type .accordion-collapse {
        background-color: white;
        width: 330px;
        max-height: 500px;
        border-bottom-right-radius: 0rem;
        border-bottom-left-radius: 0rem;
        overflow-y:scroll;
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300853F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
        transform: rotate(0deg);
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important;
    } 
    .accordion-button.collapsed::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300853F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
        transform: rotate(-180deg);
    } 
    .accordion-button:focus {
        box-shadow: 0 0 0 0 #fff;
    }
}

#legends #collapseOneLegend {
    padding-top: 1.25rem;
}

#legends .accordion-button::after {
    margin-left: 1rem !important;
}

#legends .collapsed{
    width: 150px!important;
}
#legends .accordion-button {
    width: 330px!important;
}

.legend-circles {
    position: absolute;
    right: 0.125rem;
    bottom: 0.5rem;
}

.legend-circles .circle {
    display: inline-block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

@media only screen and (max-width: 600px) {
    #legends{
        width: 320px;
        bottom: 0px !important;
    }
    .accordion-button::after {
        margin-left: auto !important;
    }
    #legends .accordion-button {
        width: 100%!important;
    }
    .accordion-item:last-of-type .accordion-collapse {
        width: unset!important;
        max-height: 400px!important;
    }
    .legend-circles {
        right: 2rem;
    }
}
</style>
<template>
  <span class="badge rounded-pill bg-success">
      <slot name="content"></slot>
  </span>
</template>

<script>
export default {
    name:"Badge"
}
</script>

<style lang="scss" scoped>
.badge {
  font-family: 'Poppins', sans-serif!important;  
  font-size: .75rem;
  background: #FFFFFF!important;
  border: 1px solid #00853F!important;
  box-sizing: border-box!important;
  border-radius: 168px!important;
  color: #00853F;
  margin-left: 1rem;
}
</style>
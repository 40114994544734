<template>
<div class="modal" :id="id" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-fullscreen-md-down">
        <div class="modal-content">
            <div class="modal-body">
                <slot name="modalBody">
                </slot>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  name: "Modal",
  props: ["id"],
  data() {
    return {
      targetId: "",
      modalInstance: Object
    };
  },
  mounted() {
    this.targetId = document.getElementById(this.$props.id);
    this.emitter.on("open-modal", this.openModal);
    this.emitter.on("close-modal", this.closeModal);
    if (this.targetId) {
      this.modalInstance = new Modal(this.targetId, {})
    }
  },
  deactivated() {
    this.closeModal()
    
  },
  methods: {
    openModal() {
      this.modalInstance.show();
    },
    closeModal() {
      this.modalInstance.hide();
    },
  },
};
</script>
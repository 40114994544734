<script>

import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';

const OlLayer = {
    props: {
        layer: {
            type: Object,
            required:true
        },
        olMap: {
            // type: Object,
            required:true          
        },
        name : {
            type: String
        },
        visible : {
            type: Boolean 
        },
    },
    data: function() {
        return {
            layerObj: [],
            currentTypeVar: '',
            newTypeVar: ''
        }
    },
    created() {
        //event handlers emmited from /components/organisms/Map/Filters/Items.vue
        // call filterLayer method
        this.emitter.on("filter-changed", this.filterLayer);
        //add layer to map Open Layer
        this.setOlLayer(this.$props.layer)
    },
    mounted() {
        //default settings         
        this.currentTypeVar = document.getElementById('tipo-de-variavel-switch').getAttribute('value');
    },
    methods: {
        setOlLayer(layer)  {
            //add layer to map
            this.$props.olMap.addLayer(
                new TileLayer({
                    name: this.$props.name,
                    visible: this.$props.visible,
                    type: "wms",
                    opacity: parseFloat(layer["TileLayer"][0].opacity),
                    zIndex: parseFloat(layer["TileLayer"][0].zIndex),
                    source: new TileWMS({
                        url:  process.env.VUE_APP_GEO_SERVER+layer["TileLayer"][0]["TileWMS"][0].url,
                        params:{
                            layers: layer["TileLayer"][0]["TileWMS"][0].params[0].layers,
                            STYLES: layer["TileLayer"][0]["TileWMS"][0].params[0].styles != undefined ? layer["TileLayer"][0]["TileWMS"][0].params[0].styles : '',
                            viewparams: layer["TileLayer"][0]["TileWMS"][0].params[0].viewparams != undefined ? layer["TileLayer"][0]["TileWMS"][0].params[0].viewparams : ''
                        },
                        serverType: "geoserver",
                        transition: 0
                    })
                })
            );
            //get this layer and put inside an array after added to map
            this.setLayer()
        },
        setLayer() {
            //get this layer and put inside an array after added to map
            this.layerObj[this.$props.name] = this.$props.olMap.getLayers().getArray().find(layer => layer.get('name') == this.$props.name);
        },
        filterLayer(event) {
            let isChecked = document.getElementById(event.id).checked
            //if the filter selected match with filer object array *get by id attribute 
            if (this.layerObj[event.id] != undefined && this.layerObj[event.id].get('name') == event.id) {
                // this.newTypeVar = document.getElementById('tipo-de-variavel-switch').getAttribute('value')
                this.filterVisible(this.layerObj[event.id],isChecked)
            }
            if (event.name == 'tamanho-do-imovel-radio') {
                    this.setViewparams(event.value)
            }
            if (event.id == 'tipo-de-variavel-switch') {
                    this.changeFilterStyleVariable(event.value)
            }
        },
        filterVisible(layer,isChecked){
            if (layer.getVisible() || !isChecked) {
                layer.setVisible(false);
            } else {
                layer.setVisible(true);
            }
        },
        setViewparams(value) {
            // set all same Radiobuttons grouped to visible false (if not selected)
            this.$props.olMap.getLayers().getArray().forEach(layer => {
                if (layer.get("name") != undefined) {
                    let source = layer.getSource()
                    let viewsparams = source.getParams().viewparams
                    if (viewsparams != '') {
                        source.updateParams({viewparams:value})
                    }
                }
            })
        },
        changeFilterStyleVariable(value) {
            this.$props.olMap.getLayers().getArray().forEach(layer => {
                let name = layer.get("name")
                if (name != undefined) {
                    let source = layer.getSource();
                    let styles = source.getParams().STYLES
                    if (styles != '' && styles != undefined) {
                        let currentStyle = this.currentTypeVar
                        let newStyle = value
                        styles = styles.replaceAll(currentStyle,newStyle)
                        source.updateParams({STYLES:styles})
                    }
                }
            })
            if (this.currentTypeVar != value) {
                this.currentTypeVar = value
            }
        }
    },
    render() {
        // console.log("render")

    }
}
export default OlLayer
</script>
<template>
<ul class="no-bullets">
    <list-node v-for="(node,index) in {...list}" :node="node" :key="index">
      <template v-slot:body>
          <slot name="body" :node="node"></slot>
      </template>      
    </list-node>
</ul>
</template>

<script>
import ListNode from "./ListNode";
export default {
  props: {
    list: Object
  },
  components: {
    ListNode
  }
}
</script>

<style lang="scss">
.no-bullets li {
    list-style-type: none;
}
</style>
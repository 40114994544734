<template>
  <div>
    <Link :id="id">
        <template v-slot:icon>
              <img class="ps-3" src="assets/link-see-all-icon.svg" :alt="$t(id,['hello'])" :title="$t(id,['hello'])">
        </template>
    </Link>
  </div>
</template>
<script>
import Link from "@/components/atoms/Link"
export default {
  name: 'LinkSeeAll',
  components:{Link},
  data() {
    return {
      id: "link-see-all"
    }
  }
}
</script>
<template>
  <Title>
     <h3 class="title filter-box"> {{$t('title-filter-box')}}</h3>
  </Title>
</template>

<script>
import Title from "@/components/atoms/Title"
export default {
    name:"TitleFilterBox",
    props: {
    'id': {
        type:String,
        required:true
        }
    },
    components:{Title}
}
</script>

<style lang="scss">
    .title {
        &.filter-box {
            font-size: 1.125rem;
            line-height: 1.688rem;
            color: #000000;
            font-weight: 800!important;
            padding-left:  3.25rem;
        }
    }
    .accordion-button {
        font-family: 'Poppins', sans-serif!important;  
        font-size: 1rem;
        background: #fff!important;
        width: 100%!important;
        height: 51px;
        padding-left: 0!important;
    }
    .accordion-button:not(.collapsed) {
        color: #000 !important;
        border-bottom: 0px !important;
        box-shadow: 0 0 0 #fff!important;
    }
    .accordion-button:focus {
        box-shadow: 0 0 0 0 #fff;
        border-bottom: 0 !important;
    }
    .accordion-item {
        border: 0!important;
    }
    .accordion-button.collapsed::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300853F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
    }
    .accordion-body {
        padding: 0!important;
    }
    ul{
        padding-left: 0!important;
    }
@media only screen and (max-width: 600px) {
    .accordion-button {
        font-size: .8rem!important;
    }
    .accordion-button.collapsed::after {
        margin-left: auto!important;
    }
    .accordion-button::after{
        margin-left: auto!important;
    }
}
</style>
<template>
    <div class="accordion-item">
        <slot name="itemBody"></slot>
        <slot name="subitemBody"></slot>
    </div>
</template>

<script>
export default {
    name: 'Link'
}
</script>

<style>

</style>
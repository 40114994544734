<template>
    <li class="node-tree">
      <slot name="body" :node="nodeComputed"></slot>
      <ul v-if="Object.keys(subitems).length > 0 && nodeComputed.type != 'Accordion'">
        <ListNode v-for="(subitem,index) in subitems" :node="subitem" :key="index"></ListNode>
      </ul>
    </li>
</template>

<script>
export default {
    name: 'ListNode',
    computed: {
      subitems() {
          return {...this.node.subitems}
      },
      nodeComputed() {
          return {...this.node}
      }
    },
    props: {
        'node': {
            type:Object,
            required:true
        }     
    },
}
</script>

<style lang="scss">
</style>

<template>
    <Slide :cd_mun="cd_mun">
        <template v-slot:title>
            {{this.$parent.$parent.$props.mun_uf_name}}
        </template>
        <template v-slot:body>
            <TableDashboard :list="getLtenureFormated" v-if="!loadingStatus"></TableDashboard>
            <loader v-if="loadingStatus"></loader>
        </template>
    </Slide>
</template>

<script>
import Slide from "../../molecules/Slide/Slide"
import TableDashboard from "../../molecules/Tables/Dashboard"
import { mapGetters } from 'vuex'
export default {
    name:"Slide2",
    components:{TableDashboard,Slide},
    props:["cd_mun"],
    computed: {
        ...mapGetters('forestActLtenure', {
            loadingStatus: 'loadingStatus',
            getLtenureFormated: 'getLtenureFormated',
        }),
    },
    watch: {
        cd_mun: function() {
            if (this.$props.cd_mun !== 0) {
                this.$store.dispatch('forestActLtenure/getLtenure',this.$props.cd_mun)
            }
        }
    }
}
</script>

<style>

</style>
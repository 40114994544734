<template>
    <div id="filters">
        <!-- FILTERS: MAIN ACCORDION -->
        <Accordion id="filterMainAccordion">
            <template v-slot:accordionItem>
                <div class="accordion-item">
                    <div class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <TitleFilterBox id="title-filter-box" />
                        </button>
                        <div class="row justify-content-between filter-button pb-3">
                            <div class="col-auto">
                                <CounterTotal class="badge-total" />
                            </div>
                            <div class="col-auto text-right">
                                <Link id="link-clear-layers" @click="resetFilters" />
                            </div>
                        </div>
                    </div>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#filterMainAccordion">
                        <!-- FILTERS: LIST / LOOP -->
                        <List :list="getFilters">
                            <template v-slot:body="slotProps">
                                <Items :node="slotProps.node" >
                                </Items>
                            </template>
                        </List>
                        <!-- //FILTERS: LIST / LOOP -->
                    </div>
                </div>
            </template>
        </Accordion>
        <!-- //FILTERS: MAIN ACCORDION -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CounterTotal from "./Filters/FilterCounterTotal"
import List from "@/components/molecules/Accordion/List"
import Link from "@/components/atoms/Link"
import Items from "@/components/organisms/Map/Filters/Items.vue"
import TitleFilterBox from '@/components/molecules/Titles/FilterBox'
import Accordion from  '@/components/atoms/Accordion/Accordion'

export default {
    name:"Filters",
    components:{List,TitleFilterBox,Accordion,Link,CounterTotal,Items},
    data(){
        return ({
             accordionId: "accordionFilter",
        })
    },
    computed: {
        ...mapGetters('mapLayers', {
            getFilters: 'getFilters'
        })
    },
    methods: {
        resetFilters: function() {
            this.emitter.emit("filter-reset");
        }
    }
}
</script>

<style lang="scss" scoped>
.badge-total {
    font-family: 'Poppins', sans-serif!important;  
    background: #00853F!important;
    border: 1px solid #00853F!important;
    color: #fff;
    margin-left: 1rem;
    margin-right: 1rem;
}
.filter-button{
    width: 100%;
    padding-left: 2rem;
}
#collapseOne {
    padding-left:3.5rem;
}
#filters{
    width: 497px;
    top:100px!important;
    left:0!important;
    bottom: unset!important;
    padding-left: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem!important;
    max-height: 570px!important;
    overflow: overlay;
    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300853F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
        transform: rotate(-180deg);
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important;
    } 
    .accordion-button:focus {
        box-shadow: 0 0 0 0 #fff;
        border-bottom: 0 !important;
    }
}
@media only screen and (max-width: 1280px) {
    #filters{
        max-height: 380px!important;
    }
}
@media only screen and (max-width: 600px) {
    #filters{
        top: 70px!important;
        margin-left: 0rem!important;
        width: 100%!important;
        padding-right: 0;
    }
    .form-check-label[data-v-009d069c] {
        font-size: .8rem;
    }
}
@media screen and (max-width: 1279px) and (min-width: 768px) {
    ._to-right .form-check-input {
          margin-right: 1.4rem!important;
    }
}
</style>

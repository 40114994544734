<template>
    <div class="accordion" :id="id">
        <slot name="accordionItem"></slot>
    </div>
</template>

<script>
export default {
    name:"Accordion",
    props:{
        'id': {
            type:String,
            required:true
        }
    }
}
</script>

<style lang="scss">
.form-check {
    font-family: 'Poppins', sans-serif!important;  
    font-size: .875rem;
    line-height: 1.313rem
}
@media only screen and (max-width: 600px) {
    .form-check {
        font-size: .7rem;
    }
}
</style>
<template>
   <Counter :count="Count" /> 
</template>
<script>
import Counter from "@/components/molecules/Badge/CounterTotal"
export default {
    name: 'FilterCounterTotal',
    data() {
      return {
        Count: 0
      }
    },
    components: { 
        Counter
    },
    mounted() {
        this.emitter.on("filter-changed", this.filterChanged);
    },
    methods: {
        filterChanged(e) {
            if(e.noCounter == undefined || e.noCounter == 0) {
                if(e.checked) {
                    this.Count++;
                }
                else {
                    this.Count--;
                }
            }

            if(this.Count < 0) {
                this.Count = 0;
            }
        }
    }   
}
</script>

<template>
    <div class="row slider pt-sm-2">
        <div class="col-sm-12 d-none d-sm-block text-center">
            <CloseButton class="close-button" />
            <h4><slot name="title"></slot></h4>
            <SeeAll class="see-all" @click.prevent="openDashboardPage" />
        </div>
        <div class="col-6 d-block d-sm-none text-start" style="position:static;z-index:40;">
            <CloseButton  />
        </div>
        <div class="col-6 d-block d-sm-none text-end">
            <SeeAll @click.prevent="openDashboardPage" style="z-index:20;" />
        </div>
        <div class="col-12 d-block d-sm-none text-center pt-2 pt-sm-0">
            <h4><slot name="title"></slot></h4>
        </div>
    </div>
    <hr class="slide"/>
    <div class="row px-5 pt-2 pb-5">
        <slot name="body"></slot>
    </div>
</template>

<script>
import CloseButton from '../../atoms/CloseButton'
import SeeAll from '../Links/SeeAll'
export default {
    name:"Slide",
    components:{CloseButton,SeeAll},
    props:{
        'slideId' : {
            type: Number
        },
        'cd_uf' : {
            type: Number
        },
        'cd_mun' : {
            type: Number
        }
    },
    methods:{
        openDashboardPage() {
            this.$router.push({ name: 'dashboard', params: { cd_uf: this.$props.cd_uf, cd_mun: [this.$props.cd_mun] } })
        }
    }
}
</script>

<style lang="scss">
    .slider {
        .close-button {
            position:absolute;
            left:15px;
            top:10px;
            z-index:20;
            img {
                width:9.33px !important;     
            } 
        }
        .see-all {
            position:absolute;
            top:10px; 
            right:15px;     
            z-index:20;      
        }
        .body {
            padding:0px 40px 40px 40px;
            word-break: break-all;
        }
    }
</style>
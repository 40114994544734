<template>
   <Counter :count="Count" /> 
</template>
<script>
import Counter from "@/components/molecules/Badge/Counter"

export default {
    name: 'FilterCounter',
    props: {
        'node': {
            type:Object,
            required:true
        }     
    },
    data() {
      return {
        Count: 0,
        NodeElm: Object
      }
    },
    components: { 
        Counter
    },
    mounted() {
        this.NodeElm = document.querySelector('[accordionId=' + this.node.name + ']')
        this.emitter.on("filter-changed", this.filterChanged);
    },
    methods: {
        filterChanged(e) {
            // Identifica se o Filtro faz parte do nó do counter //
            if(this.NodeElm !== null) {
                var searchForFilter = this.NodeElm.querySelector("#" + e.id);
               if(searchForFilter !== null && (e.noCounter == undefined || e.noCounter == 0)) {
                    if(e.checked) {
                        this.Count++;
                    }
                    else {
                        this.Count--;
                    }
                }
            }

            if(this.Count < 0) {
                this.Count = 0;
            }
        }
    }   
}
</script>

<template>
  <div class="open-layer-container p-0 m-0">
    <div ref="map-root" class="map-root-size"> 
    </div>
    <span v-if="mapLoaded">
      <Layer v-for="(item,index) in listLayers" :key="index" :layer="item" :visible="item['TileLayer'][0].visible" :name="item['TileLayer'][0].name" :olMap="map"></Layer>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import View from "ol/View";
import Map from "ol/Map";
import Layer from "@/components/atoms/Map/Layer.vue"
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { transform } from "ol/proj";
import TileWMS from 'ol/source/TileWMS';
import "ol-geocoder/dist/ol-geocoder.min.css";
import "ol/ol.css";
export default {
  name: "OpenLayer",
  components:{Layer},
  data() {
    return {
      map: Object,
      mapLoaded: false,
    };
  },
  computed: {
    ...mapGetters('mapLayers', {
        selectedLayer: 'getSelectedLayerByKey',
        listLayers: 'getLayers'
    })
  },
  mounted() {
    // this is where we create the OpenLayers map
    let map = new Map({
      // the map will be created using the 'map-root' ref
      target: this.$refs["map-root"],
      // the map view will initially show Brasl
      view: new View({
        zoom: 5,
        center: transform([-56, -6], 'EPSG:4326', 'EPSG:3857'),
        // constrainResolution: true,
      })
    })
    map.addLayer(
        new TileLayer({
          source: new OSM()
        })
    )
    
    map.on("rendercomplete", () => {
      if (!this.mapLoaded) {
        this.mapLoaded = true;
      }
    })

    this.$parent.olMap = map
    map.on('pointermove', function () {
       
        // map.forEachLayerAtPixel(evt.pixel, function(layer) {
        //   console.log(layer.get("name"))
        //   if (layer.get("name")) {
        //     console.log("entrou")
        //     showPointer = true     
        //   } else {
        //     console.log("nao entrou")
        //     showPointer = false
        //   }
        // });
        this.getTargetElement().style.cursor = 'pointer';
    });

    // map.on("moveend", function() {
    //   var zoom = map.getView().getZoom();
    //     console.log("Map zoom")
    //     console.log(zoom)
    // })
    map.on('click', (event) => {
      const vueObj = this
      const view = map.getView()
      const viewResolution = /** @type {number} */ (view.getResolution());
      const wmsSource = new TileWMS({
        url: process.env.VUE_APP_GEO_SERVER+'imaflora/wms',
        params: {'LAYERS': 'imaflora:pa_br_municipios', 'TILED': true},
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      });
      const url = wmsSource.getFeatureInfoUrl(
        event.coordinate,
        viewResolution,
        'EPSG:3857',
        {'INFO_FORMAT': 'application/json'}
      );
      if (url) {
        this.emitter.emit("open-modal")
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            vueObj.$parent.$data.cd_mun = data.features[0].properties.cd_mun
            vueObj.$parent.$data.cd_uf = data.features[0].properties.cd_uf
            vueObj.$parent.$data.mun_uf_name = data.features[0].properties.nm_mun + ' - ' + data.features[0].properties.sigla_uf
          });
      }


    });
    //used in filterChanged
    this.map = map;
  }
};
</script>
<style lang="scss">
    .open-layer-container {
      width: 100%;  
      height: 100%;
      margin: auto;
      z-index:1;
      .ol-zoom {
        top:unset!important;
        left:unset!important;
        bottom: 3rem!important;
        right: 0.5em!important;
      }
    }
    .map-root-size{
      width: 100%!important;
      height: 100%!important;
    }
</style>
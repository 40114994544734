<template>
    <loader v-if="loadingStatus"></loader>
    <v-select v-if="!loadingStatus"
        :actionHandler="handler" 
        :actionDeselect="deselected"
        :options="formatedCities"
        :value="currentCdMun"
        :selectedId="currentCdMun"
        placeholder="Escolha uma cidade"
        label="nm_mun"
        inputId="cd_mun"    
    >
    </v-select>
</template>

<script>
import vSelect from "../../atoms/Form/VueSelect.vue";
import { mapGetters, mapState } from 'vuex'
export default {
    name:"CitiesTypeahead",
    props:['siglaUf','currentCdMun'],
    data() {
        return {
            formatedCities:[]
        }
    },
    components:{vSelect},
    emits: ["selected"],
    computed: {
        ...mapState({
            states: state => state.cities.all
        }),
        ...mapGetters('cities', {
            loadingStatus: 'loadingStatus',
            getFormatedCities: 'getFormatedCities'
        }),
    },
    watch: { 
        siglaUf: function(newVal) { // watch it
            this.filterCitiesByCdUf(newVal)
        }, 
        formatedCities: function() {
            return this.getFormatedCities
        },
        loadingStatus: function() {
            if (this.loadingStatus === false) {
                if (this.$props.siglaUf != '') {
                    this.filterCitiesByCdUf(this.$props.siglaUf)
                } else {
                    this.formatedCities = this.getFormatedCities
                }
            }
        },
    },
    created() {
        this.$store.dispatch('cities/getAllCities')
    },
    methods: {
       handler(value) {
            this.$emit('selected',value.cd_mun)
       } ,
       deselected() {
       },
       filterCitiesByCdUf(sigla_uf) {
           if(sigla_uf == '') {
                 this.formatedCities = this.getFormatedCities
           }
           else {
                this.formatedCities = this.getFormatedCities.filter((city) => city.sigla_uf == sigla_uf)
           }
       }
    }

}
</script>
<template>
  <Title>
     <h3 class="title legend-box"> {{$t('title-legend-box')}}</h3>
  </Title>
</template>

<script>
import Title from "@/components/atoms/Title"
export default {
    name:"TitleLegendBox",
    props: {
    'id': {
        type:String,
        required:true
        }
    },
    components:{Title}
}
</script>

<style lang="scss">
    .title {
        &.legend-box {
            font-size: 1.125rem;
            line-height: 1.688rem;
            color: #000000;
            font-weight: 400!important;
            padding-left:  3.25rem;
        }
    }
   
    .accordion-item {
        border: 0!important;
    }
    .accordion-body {
        padding: 0!important;
    }
    ul{
        padding-left: 0!important;
    }
</style>
<template>
      <item ref="itemName">
        <template v-slot:itemBody>
            <div class="legend-item px-5" :id="'legend-heading-'+id" :style="display ? 'display:block;' : 'display:none;'" :group="group">
              <p>{{name}}</p>
            </div>
        </template>
      </item>
      <loader v-if="loadingStatus"></loader>
</template>

<script>
import Item from "@/components/atoms/Accordion/Item"
import { mapGetters } from 'vuex'
export default {
    name: 'LegendItem',
    props: {
      'id': {
        type:String,
        required:true
      },
      'name': {
        type:String,
        required:true
      },
      'endpoint': {
        type:String,
        required:true
      },
      'style': {
        type:String,
        required:true
      },
      'layer': {
        type:String,
        required:true
      },
      'group': {
        type:String,
        required:true
      },
      'display': {
        type:Boolean,
        required:true
      }
    },
    components: {
        Item
    },
    data() {
      return {
        loadingStatus: true
      }
    },
    created(){
      this.getLegendList()
    },
    computed: {
      ...mapGetters('geoserver', {
          loadingStatusLegend: 'loadingStatusLegend',
          getLegend: 'getLegend'
      }),
    },
    methods: {
          addLegendPng(imgBlob) {
            const img = document.createElement('img');
            img.src = URL.createObjectURL(imgBlob);
            document.getElementById('legend-heading-'+this.$props.id).append(img)
          },
          async getLegendList() {
            this.loadingStatus = true
            this.$store.dispatch('geoserver/getLegend',[this.$props.endpoint,this.$props.layer,this.$props.style]).then(response => {
                this.loadingStatus = false
                this.addLegendPng(response)
              }
            )
          }
    }
    
}
</script>

<style scoped>
.legend-item {
  margin-bottom: 0.5rem;
}
  .label {
    display: inline-block;
    height: 24px;
    position: relative;
    bottom: 0.25rem;
  }
  .circle {
    display: inline-block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0;
    margin-left: 3.25rem;
    margin-right: .25rem;
  }
</style>
<template>
    <div class="form-check form-switch _to-right">
        <label class="form-check-label" :for="name">{{label}}</label>
        <input class="form-check-input" type="checkbox" :ref="name" :id="id" :value="value">
    </div> 
</template>

<script>
export default {
    name: 'Switch',
    props: {
        'name': {
            type:String,
            required:true
        },
        'id': {
            type:String,
            required:true
        },        
        'value': {
            type:String,
            required:true
        },
        'label': {
            type:String,
            required:true
        },
        'checked': {
            type:String,
            required:true,
            default:"false"
        }
    },
    mounted() {   
        if (this.checked == "true") {
            this.$refs[this.name].checked = true
             this.$emit('change', this);
        } else {
            this.$refs[this.name].checked = false
        }
    }
}
</script>

<style lang="scss" scoped>
    ._to-right .form-check-input {
        float: right !important; //positioning right of label
        margin-right: 1rem;
        margin-top: 1rem;
    }
    .form-switch {
        width: 100%;
        height: 51px;
        padding:0px;
    }
    .form-check-input {
        border: 1px solid rgba(0, 133, 63, 0.5);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 133, 63, 0.5%29'/%3e%3c/svg%3e")!important;
    }
    .form-check-input:checked {
        background-color: transparent;
        border-color: #00853F;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 133, 63, 1%29'/%3e%3c/svg%3e")!important;
    }
    .form-check-label{
        font-family: 'Poppins', sans-serif!important;  
        font-size: 1rem;
        height: 35px;
        margin-top: 15px;
    }

@media only screen and (max-width: 600px) {
    .form-check-label{
        font-size: .8rem;
    }
}
</style>

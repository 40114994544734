<template>
    <Modal id="modal-slider">
      <template v-slot:modalBody>
        <div id="carouselExampleIndicators" class="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-interval="false">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
                <Slide1 ref="slide1" :slideId=1 :cd_mun="cd_mun" :cd_uf="cd_uf" />
            </div>
            <div class="carousel-item">
                <Slide2 ref="slide2" :slideId=2 :cd_mun="cd_mun" :cd_uf="cd_uf" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </template>
    </Modal>
</template> 

<script>
import Modal from "../../atoms/Modals/Modal"
import Slide1 from "./Slide1"
import Slide2 from "./Slide2"
export default {
    name:"Slider",
    props: {
        'cd_mun': {
            type:Number,
            required:true,
        },
        'cd_uf': {
            type:Number,
            required:true,
        },
        'mun_uf_name': {
            type:String,
            required:true,
        },
    },
  components: {Modal,Slide1,Slide2},
    watch: { 
        cd_mun: function() {
            return this.$props.cd_mun
        }
    }
}
</script>

<style lang="scss">
.carousel-control-next {
  right: -42px !important; 
}

.carousel-control-prev {
  left: -42px !important;
}

@media only screen and (max-width: 768px) {
  .carousel-control-next {
    right: -20px !important; 
    // top: 25px !important;
  }

  .carousel-control-prev {
    left: -20px !important;
    // top: 25px !important;
  }
  .modal {
    top: 6px;
    left: -6px;
  }
}



</style>
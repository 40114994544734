<template>
    <badge>
        <template v-slot:content>
            {{count}}
        </template>
    </badge>
</template>

<script>
import Badge from "@/components/atoms/Badge"
export default {
    name:"Counter",
    components:{Badge},
    props:{
        'count':{
            type:Number,
            required:true
        }
    }

}
</script>

<style>

</style>
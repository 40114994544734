<template>
  <Doughnut>
      <template v-slot:title>
         <Title id="title-chart-doughnut-deficit-app-ha" class="text-center"></Title> 
      </template>
  </Doughnut>
</template>

<script>
import Doughnut from "../../atoms/Charts/Doughnut"
import Title from "../../molecules/Titles/Chart"
export default {
    name:"DoughnutDeficitAppHa",
    components: {Doughnut,Title}
}
</script>

<style>

</style>
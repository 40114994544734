
<template>
    <Slide :cd_mun="cd_mun">
        <template v-slot:title>
            {{this.$parent.$parent.$props.mun_uf_name}}
        </template>
        <template v-slot:body>
            <div class="col-12 col-sm-12 col-lg-6">
                <BigNumber :number="getBigNumbersCount" paragraph="Imóveis" v-if="!loadingStatus" :formatNumber="false"></BigNumber>
                <loader v-if="loadingStatus"></loader>
            </div>
            <div class="col-12 col-sm-12 col-lg-6">
                <BigNumber :number="getBigNumbersArea" paragraph="Hectares" v-if="!loadingStatus"></BigNumber>
                <loader v-if="loadingStatus"></loader>
            </div>
            <hr class="slide"/>
            <div class="row justify-content-around">
                <div class="col-12 col-sm-12 col-lg-6 mt-3">
                    <DoughnutDeficitAppHa :dataChart="getDeficitsApp"  v-if="!loadingStatusDeficits"></DoughnutDeficitAppHa>
                    <loader v-if="loadingStatusDeficits"></loader>
                </div>
                <div class="col-12 col-sm-12 col-lg-6 mt-5 mt-md-3">
                    <DoughnutDeficitRLHa :dataChart="getDeficitsLR"  v-if="!loadingStatusDeficits"></DoughnutDeficitRLHa>
                    <loader v-if="loadingStatusDeficits"></loader>
                </div>
            </div>
        </template>
    </Slide>
</template>

<script>

import Slide from "../../molecules/Slide/Slide"
import DoughnutDeficitAppHa from "../../molecules/Charts/DoughnutDeficitAppHa"
import DoughnutDeficitRLHa from "../../molecules/Charts/DoughnutDeficitRLHa"
import BigNumber from "../../molecules/BigNumber/Box"
import { mapGetters } from 'vuex'

export default {
    name:"Slide1",
    props:["cd_mun"],
    components:{Slide,DoughnutDeficitAppHa,BigNumber,DoughnutDeficitRLHa},
    computed: {
        ...mapGetters('forestActBigNumbers', {
            loadingStatus: 'loadingStatus',
            getBigNumbersCount: 'getBigNumbersCount',
            getBigNumbersArea: 'getBigNumbersArea'
        }),
        ...mapGetters('forestActDeficits', {
            loadingStatusDeficits: 'loadingStatus',
            getDeficitsApp: 'getDeficitsApp',
            getDeficitsLR: 'getDeficitsLR'
        }),
    },
    watch: {
        cd_mun: function() {
            if (this.$props.cd_mun !== 0) {
                this.$store.dispatch('forestActBigNumbers/getBigNumbers',this.$props.cd_mun)
                this.$store.dispatch('forestActDeficits/getDeficits',this.$props.cd_mun)
            }
        }
    }
}
</script>
<style>
    hr.slide {
        border-top: 1px dashed #525656;
        background-color: transparent;
    }
</style>